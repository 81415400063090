export default function() {
  /* 表格列头 */
  return [
    { label: "序号", type: "index", width: 80 },
    { label: "分类名称", prop: "categoryName", width: 300 },
    // { label: "分类主图", prop: "img", slots: "img" },
    { label: "状态", prop: "status", slots: "status" },
    { label: "所属门店", prop: "store_id", slots: "store_id", hidden: true },
    {
      label: "创建时间",
      prop: "createTime",
      width: 180,
      // slots: "state",
    },
    {
      label: "操作",
      prop: "operation",
      width: 150,
      slots: "operation",
      // fixed: "right",
    },
  ];
}
