import axios from "axios";
import { request } from "@/utils/request";
// 查询一级分类
export function selectCategory(data) {
  return request({
    url: `/admin/goods/selectCategory`,
    method: "POST",
    data,
  });
}

// 新增一级分类
export function addCategory(data) {
  return request({
    url: `/admin/goods/addCategory`,
    method: "POST",
    data,
  });
}

/* 修改分类 */
export function updateCategory(data) {
  return request({
    url: `/admin/goods/updateCategory`,
    method: "POST",
    data,
  });
}

// 删除分类
export function deleteCategory(data) {
  return request({
    url: `/admin/goods/deleteCategory`,
    method: "POST",
    data,
  });
}

// 通过一级分类id查商品
export function selectGoods(data) {
  return request({
    url: `/admin/goods/selectGoods`,
    method: "POST",
    data,
  });
}

// 上传商品
export function addGoods(data) {
  return request({
    url: `/admin/goods/addGoods`,
    method: "POST",
    data,
  });
}

// 修改商品
export function updateGoods(data) {
  return request({
    url: `/admin/goods/updateGoods`,
    method: "POST",
    data,
  });
}

/* 修改商品sku状态 */
export function updateSkuState(data) {
  return request({
    url: `/admin/goods/updateSkuState`,
    method: "POST",
    data,
  });
}

// 删除商品
export function deleteGoodbyid(data) {
  return request({
    url: `/admin/goods/deleteGoodsbyid`,
    method: "POST",
    data,
  });
}

// 新增
export function addSku(data) {
  return request({
    url: `/admin/goods/addSku`,
    method: "POST",
    data,
  });
}

// 查询
export function selectSku(data) {
  return request({
    url: `/admin/goods/selectSku`,
    method: "POST",
    data,
  });
}

// 删除
export function deleteSku(id) {
  return request({
    url: `/admin/goods/deleteSku`,
    method: "POST",
    data: { id },
  });
}

/* 商品图管理 */
export function selectImg(data) {
  return request({
    url: `/admin/goods/selectImg`,
    method: "POST",
    data,
  });
}

export function addImg(data) {
  return request({
    url: `/admin/goods/addImg`,
    method: "POST",
    data,
  });
}

export function updateImg(data) {
  return request({
    url: `/admin/goods/updateImg`,
    method: "POST",
    data,
  });
}

export function deleteImg(data) {
  return request({
    url: `/admin/goods/deleteImg`,
    method: "POST",
    data,
  });
}
